import React, { Suspense, lazy, useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { getStoredTheme, toggleTheme } from './components/helper.js';

import AdminFormBuilder from './pages/ADMIN/AdminFormBuilder.js';
// Initialize Stripe with your publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

// General pages
const Home = lazy(() => import('./pages/Home'));
const Login = lazy(() => import('./pages/Login'));
const Register = lazy(() => import('./pages/Register'));
const Dashboard = lazy(() => import('./pages/Dashboard'));

// Support pages
const Support = lazy(() => import('./pages/Support/Support'));
const PrivacyPolicy = lazy(() => import('./pages/Support/PrivacyPolicy.js'));

// Admin
const Admin = lazy(() => import('./pages/ADMIN/Admin.js'));
const FormCreator = lazy(() => import('./pages/ADMIN/AdminFormBuilder.js'));

// Team pages
const TeamHome = lazy(() => import('./pages/Team/Team_Home'));
const AdminHome = lazy(() => import('./pages/Team/Admin/Admin'));
const Manage = lazy(() => import('./pages/Team/Admin/Manage'));
const RolesManagement = lazy(() => import('./pages/Team/Admin/Roles'));
const Staff = lazy(() => import('./pages/Team/Staff'));
const ViewStaff = lazy(() => import('./pages/Team/ViewStaff'));
const Evaluation = lazy(() => import('./pages/Team/Evaluation.js'));


const FormRenderer = lazy(() => import('./pages/FormRenderer.js'));
const FormDashboard = lazy(() => import('./pages/ADMIN/FormDashboard.js'));


// Registering pages
const RegisterTeam = lazy(() => import('./pages/register/register-team'));
const PlanTeam = lazy(() => import('./pages/register/plan'));
const PaymentTeam = lazy(() => import('./pages/register/payment'));
const SetupRolesTeam = lazy(() => import('./pages/register/setup-roles'));

// Financial pages
const CheckoutForm = lazy(() => import('./pages/Financial/CheckoutForm'));
const PaymentSuccessfull = lazy(() => import('./pages/Financial/payment-success'));
const PaymentCancelled = lazy(() => import('./pages/Financial/payment-cancelled'));

function App() {

    // Theme state
    const [theme, setTheme] = useState(getStoredTheme());

    // Function to toggle theme
    const handleToggleTheme = () => {
        const newTheme = toggleTheme(theme); // Toggle and set theme in localStorage and document
        setTheme(newTheme); // Update theme in the app state
    };

    return (
        <Router>
            <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/support" element={<Support />} />
                    <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/team/:teamName" element={<TeamHome />} />
                    <Route path="/team/:teamName/home" element={<TeamHome />} />
                    <Route path="/team/:teamName/admin" element={<AdminHome />} />
                    <Route path="/team/:teamName/manage" element={<Manage />} />
                    <Route path="/team/:teamName/admin/roles" element={<RolesManagement />} />
                    <Route path="/team/:teamName/staff" element={<Staff />} />
                    <Route path="/team/:teamName/staff/:userName" element={<ViewStaff />} />
                    <Route path="/team/:teamName/evaluation" element={<Evaluation />} />

                    {/* Admin */}
                    <Route path="/admin" element={<Admin />} />
                    <Route path="/admin/createform" element={<AdminFormBuilder />} />
                    <Route path="/forms/:id" element={<FormRenderer />} />
                    <Route path="/forms/:id/dashboard" element={<FormDashboard />} />

                    {/* Register Team Routes */}
                    <Route path="/register-team" element={<RegisterTeam />} />
                    <Route path="/register-team/plan" element={<PlanTeam />} />
                    <Route path="/register-team/payment" element={<PaymentTeam />} />
                    <Route path="/register-team/setup-roles" element={<SetupRolesTeam />} />

                    {/* Financial Routes with Elements wrapper for Stripe */}
                    <Route
                        path="/register-team/checkout"
                        element={<CheckoutForm />}
                    />
                    <Route path="/register-team/payment-successful" element={<PaymentSuccessfull />} />
                    <Route path="/register-team/payment-cancelled" element={<PaymentCancelled />} />
                </Routes>
            </Suspense>
        </Router>
    );
}

export default App;
