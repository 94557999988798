import React from 'react';
import './../styles/footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <p>&copy; 2024 StaffOverseer - All Rights Reserved</p>
            <div className="footer-links">
                <a href="mailto:contact@staffoverseer.com" className="footer-link">Contact Us</a>
                <a href="/support">Support</a>
            </div>
        </footer>
    );
};

export default Footer;
