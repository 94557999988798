import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './../../styles/style.css';
import './../../styles/FormBuilder.css';
import Header from './../../components/header';
import Footer from './../../components/footer';
import { getStoredTheme, setTheme, toggleTheme, hasPermission } from '../../components/helper';

const AdminFormBuilder = () => {
    const [formName, setFormName] = useState('');
    const [customId, setCustomId] = useState('');
    const [fields, setFields] = useState([]); // Ensuring it's always an array
    const [newField, setNewField] = useState({ type: 'text', label: '', options: [] });
    const [forms, setForms] = useState([]); // List of all forms
    const PUBLIC_IP = process.env.REACT_APP_PUBLIC_IP;

    // Fetch forms from the backend
    const fetchForms = async () => {
        try {
            const response = await axios.get(`${PUBLIC_IP}/api/forms`);
            setForms(response.data);
        } catch (error) {
            console.error("Error fetching forms:", error);
        }
    };

    useEffect(() => {
        fetchForms(); // Load forms on component mount
    }, []);

    const addField = () => {
        setFields([...fields, { ...newField, id: Date.now().toString() }]);
        setNewField({ type: 'text', label: '', options: [] });
    };

    const validateCustomId = (id) => {
        const regex = /^[a-zA-Z0-9-]+$/;
        return regex.test(id);
    };

    const saveForm = async () => {
        if (!validateCustomId(customId)) {
            alert("Custom ID must be alphanumeric and can include hyphens only.");
            return;
        }

        try {
            await axios.post(`${PUBLIC_IP}/api/forms`, { formName, customId, fields });
            alert('Form saved successfully!');
            fetchForms(); // Refresh the list of forms
        } catch (error) {
            if (error.response?.status === 409) {
                alert("Custom ID already exists. Please choose another one.");
            } else {
                console.error("Error saving form:", error);
            }
        }
    };

    return (
        <div>
            <Header />
            <div className="form-builder-container" data-theme="light">
                <main className="form-builder-content">
                    <h1 className="form-builder-title">Create a New Form</h1>
                    <div className="form-name-section">
                        <input
                            type="text"
                            className="form-name-input"
                            placeholder="Form Name"
                            value={formName}
                            onChange={(e) => setFormName(e.target.value)}
                        />
                    </div>
                    <div className="form-id-section">
                        <input
                            type="text"
                            className="form-id-input"
                            placeholder="Custom ID (e.g., survey-2024)"
                            value={customId}
                            onChange={(e) => setCustomId(e.target.value)}
                        />
                    </div>
                    <div className="new-field-section">
                        <h2 className="section-title">New Field</h2>
                        <div className="field-type-select">
                            <select
                                value={newField.type}
                                onChange={(e) => setNewField({ ...newField, type: e.target.value })}
                            >
                                <option value="text">Text</option>
                                <option value="radio">Radio</option>
                            </select>
                        </div>
                        <input
                            type="text"
                            className="field-label-input"
                            placeholder="Field Label"
                            value={newField.label}
                            onChange={(e) => setNewField({ ...newField, label: e.target.value })}
                        />
                        {newField.type === 'radio' && (
                            <input
                                type="text"
                                className="field-options-input"
                                placeholder="Options (comma-separated)"
                                onChange={(e) =>
                                    setNewField({
                                        ...newField,
                                        options: e.target.value.split(',').map((opt) => ({ label: opt, value: opt })),
                                    })
                                }
                            />
                        )}
                        <button className="add-field-btn" onClick={addField}>Add Field</button>
                    </div>
                    <div className="form-preview-section">
                        <h2 className="section-title">Form Preview</h2>
                        {/* Ensuring fields is always an array and preventing map on undefined */}
                        {fields && fields.length > 0 ? (
                            fields.map((field) => (
                                <div key={field.id} className="preview-field">
                                    <label className="preview-label">{field.label}</label> ({field.type})
                                </div>
                            ))
                        ) : (
                            <p>No fields added yet.</p>
                        )}
                    </div>
                    <button className="save-form-btn" onClick={saveForm}>Save Form</button>

                    <div className="form-list-section">
                    <h2 className="section-title">All Forms</h2>
                    <table className="form-list-table">
                        <thead>
                            <tr>
                                <th>Form Name</th>
                                <th>Form ID</th>
                                <th>Response Count</th>
                                <th>Actions</th>
                                <th>Manage</th>
                            </tr>
                        </thead>
                        <tbody>
                            {forms && forms.length > 0 ? (
                                forms.map((form) => (
                                    <tr key={form.id}>
                                        <td>{form.formName}</td>
                                        <td>{new Date(form.createdAt).toLocaleString()}</td>
                                        <td>{form.responseCount}</td>
                                        <td>
                                            <a href={`/forms/${form.id}`} target="_blank" rel="noopener noreferrer">
                                                View Form
                                            </a>
                                        </td>
                                        <td>
                                            <a href={`/forms/${form.id}/dashboard`} target='_blank' rel='noopener norefferer'>
                                                View Dashboard
                                            </a>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="4">No forms available.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                </main>
            </div>
            <Footer />
        </div>
    );
};

export default AdminFormBuilder;
